import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import MapWithMarker from './mapwithmarker';

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const latitude = -6.227201088322561; // contoh latitude
  const longitude = 106.85469824355216; // contoh longitude

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);

    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-6">
            <div className="row">
              <div className="section-title">
                <h2>Contact Info</h2>

                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span> <br />
                  {props.data ? props.data.address : "loading"} </p>
                <br />
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "} <br />
                  {props.data ? props.data.phone : "loading"}
                </p>
                <br />
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "} <br />
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <MapWithMarker latitude={latitude} longitude={longitude} />
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.linkedin : "/"}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>2024 - Trilogi Persada
            {/* &copy; 2023 Issaaf Kattan React Land Page Template. Design by{" "}
            <a href="http://www.templatewire.com" rel="nofollow">
              TemplateWire
            </a> */}
          </p>
        </div>
      </div>
    </div>
  );
};
