import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const MapWithMarker = ({ latitude, longitude }) => {

    const icon = L.icon({ iconUrl: "/img/marker-icon.png" });

    return (
        <MapContainer
            center={[latitude, longitude]}
            zoom={15}
            style={{ height: '400px', width: '100%' }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[latitude, longitude]} icon={icon}>
                <Popup>
                    Here
                </Popup>
            </Marker>
        </MapContainer>
    );
};

export default MapWithMarker;