import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Visi</h3>
              <div className="list-style">
                <ul>
                  {props.data
                    ? props.data.Why.map((d, i) => (
                      <p key={`${d}-${i}`}>{d}</p>
                    ))
                    : "loading"}
                </ul>
              </div>
              <h3>Misi</h3>
              <div className="list-style">
                <ul>
                  {props.data
                    ? props.data.Why2.map((d, i) => (
                      <p key={`${d}-${i}`}> {d}</p>
                    ))
                    : "loading"}
                </ul>
              </div>
              <h3>Mengapa Trilogi</h3>
              <div className="list-style">
                <div className="">
                  <ul>
                    {props.data
                      ? props.data.Why3.map((d, i) => (
                        <li key={`${d}-${i}`}> {d}</li>
                      ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
